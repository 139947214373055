import React, { FC, useContext } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { ReactComponent as IconArrow } from 'iadocs/assets/images/iconArrow.svg'
import { DarkThemeContext } from 'core/contexts/DarkThemeContext'
import { DOCUMENTS_OPTIONS } from 'iadocs/const/Options'
import { IconSelector } from 'iadocs/components/IconSelector'
import { IconType } from 'iadocs/type/IconType'

export const DocumentHeader: FC<{
  type: IconType
  processFile?: () => void
  setType?: (type: string | null) => void
  error?: string | null
}> = ({ type, processFile, setType, error }) => {
  const { t } = useTranslation()
  const { isDarkTheme } = useContext(DarkThemeContext)

  const typeClasses = {
    extract: 'group-hover:bg-green-1400 group-hover:dark:bg-blue-1450',
    typing: 'group-hover:bg-green-1500 group-hover:dark:bg-green-1500',
    unbundle: 'group-hover:bg-purple-1150 group-hover:dark:bg-purple-1150',
  }

  return (
    <div className={`document-card-${type} group`}>
      <div className={`document-header-${type} ${typeClasses[type] || ''}`}>
        <div
          className={`document-image-${type} group-hover:text-white dark:group-hover:text-black`}
        >
          <IconSelector type={type} isDarkTheme={isDarkTheme} />
        </div>
      </div>
      <div className={`document-content`}>
        <h2 className={`h2-${type}`}>{t(`iadocs.card.${type}.title`)}</h2>
        <p>
          <Trans i18nKey={`iadocs.card.${type}.description`} />
        </p>
        {type === 'extract' ? (
          <select
            className={`select-document-type ${error === 'card.extract.error.typeDocumentMissing' ? 'error-field' : ''}`}
            id="options"
            name="options"
            onChange={(e) => setType && setType(e.target.value)}
          >
            <option value="">{t('iadocs.card.extract.select.default')}</option>
            {DOCUMENTS_OPTIONS.map((option, index) => (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        ) : (
          ''
        )}
        <button
          onClick={processFile}
          className={`${type !== 'extract' ? 'mt-[30px]' : ''} group-hover:bg-blue-1400 group-hover:text-white group-hover:dark:text-purple-1100 group-hover:dark:bg-purple-1700`}
        >
          {t('iadocs.card.button.launch')}
          <IconArrow />
        </button>
      </div>
    </div>
  )
}
