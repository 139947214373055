export const ROUTE_PATHS = {
  IASTART: '/iastart',
  IASTART_CONVERSATION: '/iastart/conversation/:usage',
  IASTART_APP: '/iastart/app/:app',
  IADOCS: '/iadocs',
  IADOCS_EXTRACT: '/iadocs/extract',
  IADOCS_TYPING: '/iadocs/typing',
  IADOCS_UNBUNDLE: '/iadocs/unbundle',
  ERROR: '/error',
}
