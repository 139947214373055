import { usageParams } from 'iastart/constants/usage'
import { resetAllConversations } from 'iastart/store/slices/conversationSlice'
import { useDispatch } from 'react-redux'
import { useContext, useEffect } from 'react'
import { IaStartCard } from 'iastart/components/IaStartCard'
import { CurrentHeaderContext } from 'core/contexts/CurrentHeaderContext'

export const IaStart = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(resetAllConversations())
  }, [])
  const { setHeader } = useContext(CurrentHeaderContext)
  useEffect(() => {
    setHeader({
      svgPath: 'logoIaStart.svg',
      svgPathDark: 'logoIaStartDark.svg',
      accroche: 'iastart.accroche',
      column: true,
    })
  }, [])

  return (
    <div className="w-full dark:bg-gray-1600 h-full">
      <div className="cards-wrapper">
        {usageParams.map((param, index) => (
          <IaStartCard key={index} {...param} />
        ))}
      </div>
      <div className="cards-wrapper-mobile">
        {usageParams.map((param, index) => (
          <IaStartCard key={index} {...param} isMobile />
        ))}
      </div>
    </div>
  )
}
