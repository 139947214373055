import { IPrompt } from 'iastart/interfaces/Prompt'
import { useFieldArray, useForm } from 'react-hook-form'
import { useConverseMutation } from 'iastart/api/conversation'
import { IConverseBody } from 'iastart/interfaces/Conversation'
import { IApplication } from 'iastart/interfaces/Application'
import {
  addMessage,
  IMessage,
  resetConversation,
  UserType,
} from 'iastart/store/slices/conversationSlice'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

export type PromptTestFormData = {
  fields: { name: string; value: string }[]
}
export const usePromptVariables = (
  prompt: IPrompt,
  application: IApplication,
  setIsSending: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const dispatch = useDispatch()
  const [converse, { isLoading: isSending }] = useConverseMutation()
  useEffect(() => {
    setIsSending(isSending)
  }, [isSending])
  const extractVariablesFromPrompt = (promptText: string): string[] => {
    const regex = /\{([a-zA-Z0-9]+)\}/g
    const matches = []
    let match

    while ((match = regex.exec(promptText)) !== null) {
      matches.push(match[1].trim())
    }

    return matches
  }
  const variables = extractVariablesFromPrompt(prompt.prompt)
  const { control, handleSubmit, register, reset, getValues } =
    useForm<PromptTestFormData>({
      defaultValues: {
        fields: variables.map((field) => ({ name: field, value: '' })),
      },
    })
  const { fields } = useFieldArray({
    control,
    name: 'fields',
  })
  const onSubmit = (data: PromptTestFormData) => {
    const body: IConverseBody = {
      application: application.nomTechnique,
      params: {},
    }
    data.fields.forEach((field) => {
      body.params[field.name] = field.value
    })
    converse(body)
      .unwrap()
      .then((response) => {
        dispatch(resetConversation({ application: application.nomTechnique }))
        const iaMessage: IMessage = {
          type: UserType.ASSISTANT,
          timestamp: Date.now(),
          content: response.answer,
          id: response.message_id,
        }
        dispatch(
          addMessage({
            application: application.nomTechnique,
            message: iaMessage,
            guid: response.guid,
          })
        )
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return {
    variables,
    fields,
    handleSubmit,
    register,
    onSubmit,
  }
}
