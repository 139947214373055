import {
  DEFAULT_APP_COLOR,
  DEFAULT_APP_DARK_COLOR,
  DEFAULT_APP_ICONE,
  DEFAULT_APP_ICONE_DARK,
} from 'core/constants/general'
import { IApplicationService } from 'iastart/interfaces/Application'

export const getIconeInfo = (service: IApplicationService) => {
  let color, colorDark, svgPath, svgPathDark, imageSmall, imageSmallDark
  try {
    const parsedIcon = service && JSON.parse(service?.icone)
    if (parsedIcon) {
      ;({ color, colorDark, imageSmall, imageSmallDark } = parsedIcon)
    }
    svgPath = imageSmall
    svgPathDark = imageSmallDark
  } catch (error) {
    color = DEFAULT_APP_COLOR
    colorDark = DEFAULT_APP_DARK_COLOR
    svgPath = DEFAULT_APP_ICONE
    svgPathDark = DEFAULT_APP_ICONE_DARK
  }

  return { color, colorDark, svgPath, svgPathDark }
}
