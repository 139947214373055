import { IApplication } from 'iastart/interfaces/Application'
import { useNavigate } from 'react-router'
import { ROUTE_PATHS } from 'core/constants/routePath'
import { useGetPromptQuery } from 'iastart/api/prompt'
import { useEffect } from 'react'

export const useTestPrompt = (application: IApplication) => {
  const navigate = useNavigate()
  const activePrompt = application.prompts.find((prompt) => prompt.actif)
  useEffect(() => {
    if (!activePrompt) {
      navigate(ROUTE_PATHS.ERROR)
    }
  }, [activePrompt])

  const { data: prompt, isLoading: isLoadingPrompt } = useGetPromptQuery(
    { promptId: activePrompt?.id ?? 0 },
    { skip: !activePrompt }
  )

  return { prompt, isLoadingPrompt }
}
