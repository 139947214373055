import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router'
import { useGetApplicationByNameQuery } from 'iastart/api/application'
import { skipToken } from '@reduxjs/toolkit/query'
import { useContext, useEffect } from 'react'
import { ROUTE_PATHS } from 'core/constants/routePath'
import { CurrentHeaderContext } from 'core/contexts/CurrentHeaderContext'
import { getIconeInfo } from 'iastart/service/service'

export const useAppIastart = () => {
  const { app } = useParams()
  const navigate = useNavigate()
  const { setHeader } = useContext(CurrentHeaderContext)

  const { data: applications, isLoading: isApplicationLoading } =
    useGetApplicationByNameQuery(app ? { applicationName: app } : skipToken)
  useEffect(() => {
    if (isApplicationLoading) return

    if (!applications || applications.length === 0) {
      navigate(ROUTE_PATHS.ERROR)
      return
    }

    const application = applications[0]
    const { svgPath, svgPathDark } = getIconeInfo(application.service)

    setHeader({
      svgPath,
      svgPathDark,
      accroche: application.nomApplication,
      column: false,
    })
  }, [applications, isApplicationLoading])

  return {
    isApplicationLoading,
    application: applications?.[0] ?? null,
  }
}
