import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export enum UserType {
  USER = 'user',
  ASSISTANT = 'assistant',
}

export interface IMessage {
  id?: number
  type: UserType
  timestamp: number
  content: string
  feedback?: number
}

export interface IConversation {
  guid: string
  messages: IMessage[]
}

export interface ConversationState {
  messagesByApp: {
    [application: string]: IConversation
  }
}

const initialState: ConversationState = {
  messagesByApp: {},
}

const conversationSlice = createSlice({
  name: 'conversation',
  initialState,
  reducers: {
    addMessage: (
      state,
      action: PayloadAction<{
        application: string
        message: IMessage
        guid: string
      }>
    ) => {
      const { application, message, guid } = action.payload
      if (!state.messagesByApp[application]) {
        state.messagesByApp[application] = {
          guid: guid,
          messages: [message],
        }
      } else {
        state.messagesByApp[application].messages.push(message)
      }
    },
    resetConversation: (
      state,
      action: PayloadAction<{ application: string }>
    ) => {
      const { application } = action.payload
      state.messagesByApp[application] = { messages: [], guid: '' }
    },
    resetAllConversations: (state) => {
      state.messagesByApp = {}
    },
    addFeedback: (
      state,
      action: PayloadAction<{
        application: string
        feedback: number
        id: number
      }>
    ) => {
      const { application, feedback, id } = action.payload
      const conversation = state.messagesByApp[application]

      if (conversation) {
        const message = conversation.messages.find((msg) => msg?.id === id)
        if (message) {
          message.feedback = feedback
        }
      }
    },
  },
})
export const getMessages = (
  state: { conversation: ConversationState },
  application: string
) => {
  return state.conversation.messagesByApp[application]?.messages || []
}

export const getGuid = (
  state: { conversation: ConversationState },
  application: string
) => {
  return state.conversation.messagesByApp[application]?.guid || null
}

export const {
  addMessage,
  resetConversation,
  resetAllConversations,
  addFeedback,
} = conversationSlice.actions

export default conversationSlice.reducer
