import { baseApi } from 'core/api/base'
import { IExtract } from 'iadocs/interfaces/IExtract'
import { IUnbundle } from 'iadocs/interfaces/IUnbundle'
import { API_BASE_URL_IADOCS } from 'core/constants/general'
import { IDocument } from 'iadocs/interfaces/IDocument'

const EXTRACT_ENDPOINT = API_BASE_URL_IADOCS + '/extract'
const UNBUNDLE_ENDPOINT = API_BASE_URL_IADOCS + '/unbundle'

const DocumentApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    downloadFile: builder.query<Blob, number>({
      query: (documentId: number) => ({
        url: API_BASE_URL_IADOCS + `/documents/${documentId}/download`,
        responseHandler: (response: Response) =>
          response.blob() as Promise<Blob>,
      }),
    }),
    extract: builder.mutation<
      IExtract,
      {
        idDossier: string
        files: string[]
        type: string
        origine: string
      }
    >({
      query: ({ idDossier, files, type, origine }) => ({
        url: EXTRACT_ENDPOINT,
        method: 'POST',
        body: { idDossier, files, type, origine },
      }),
      invalidatesTags: [],
    }),
    typing: builder.mutation<
      {
        documents: IDocument[]
      },
      {
        idDossier: string
        file: string
        origine: string
        extraction: boolean
        documentUnique: boolean
      }
    >({
      query: ({ idDossier, file, origine, extraction, documentUnique }) => ({
        url: UNBUNDLE_ENDPOINT,
        method: 'POST',
        body: { idDossier, file, origine, extraction, documentUnique },
      }),
      invalidatesTags: [],
    }),
    unbundle: builder.mutation<
      IUnbundle,
      {
        idDossier: string
        file: string
        origine: string
        extraction: boolean
      }
    >({
      query: ({ idDossier, file, origine, extraction }) => ({
        url: UNBUNDLE_ENDPOINT,
        method: 'POST',
        body: { idDossier, file, origine, extraction },
      }),
      invalidatesTags: [],
    }),
  }),
})

export const {
  useExtractMutation,
  useTypingMutation,
  useUnbundleMutation,
  useLazyDownloadFileQuery,
} = DocumentApi
export { DocumentApi }
