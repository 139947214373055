import React from 'react'
import { IPrompt } from 'iastart/interfaces/Prompt'
import { usePromptVariables } from 'iastart/hooks/usePromptVariables'
import { useTranslation } from 'react-i18next'
import { ReactComponent as SendIcon } from 'core/assets/images/sendIcon.svg'
import { IApplication } from 'iastart/interfaces/Application'

export const VariablesInput: React.FC<{
  prompt: IPrompt
  application: IApplication
  setIsSending: React.Dispatch<React.SetStateAction<boolean>>
}> = ({ prompt, application, setIsSending }) => {
  const { variables, onSubmit, fields, register, handleSubmit } =
    usePromptVariables(prompt, application, setIsSending)
  const { t } = useTranslation()
  if (variables.length === 0) {
    return <div>{t('iastart.noVar')}</div>
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-row">
      <div className="var-input-wrapper">
        {fields.map((field, index) => (
          <div key={field.name} className="flex items-center">
            <label htmlFor={`fields.${index}.value`} className="mr-2 w-[80px]">
              {field.name}
            </label>
            <input
              id={`fields.${index}.value`}
              {...register(`fields.${index}.value`)}
              className="var-input"
              placeholder={t('iastart.placeholder')}
            />
          </div>
        ))}
      </div>
      <button type="submit" className="btn-send-message btn-send-wrapper">
        <div className="uppercase mb-[5px] font-medium">
          {t('iastart.envoyer')}
        </div>
        <SendIcon />
      </button>
    </form>
  )
}
