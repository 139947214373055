import React from 'react'

import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { UseKeycloakService } from 'core/packages/KeycloakProvider'
import Loader from 'core/components/common/Loader'
import { Provider } from 'react-redux'
import store, { persistor } from 'core/store'
import { PersistGate } from 'redux-persist/integration/react'
import ErrorHandler from 'core/components/common/ErrorHandler'
import { Layout } from 'core/pages/Layout'
import 'core/assets/styles/style.css'
import { ROUTE_PATHS } from 'core/constants/routePath'
import { Error500 } from 'core/components/common/Error500'
import { Error404 } from 'core/components/common/Error404'
import { LayoutApp } from 'core/components/layout/LayoutApp'
import { IaStart } from 'iastart/pages/IaStart'
import { Conversation } from 'iastart/pages/Conversation'
import { IaDocs } from 'iadocs/pages/IaDocs'
import { ExtractPage } from 'iadocs/pages/ExtractPage'
import { UnbundlePage } from 'iadocs/pages/UnbundlePage'
import { TypingPage } from 'iadocs/pages/TypingPage'
import { IaStartApps } from 'iastart/pages/IaStartApps'

function App() {
  const kc = UseKeycloakService()
  const isLoading = !kc.isInitialized()
  const {
    IADOCS,
    IASTART,
    IASTART_CONVERSATION,
    IASTART_APP,
    ERROR,
    IADOCS_EXTRACT,
    IADOCS_TYPING,
    IADOCS_UNBUNDLE,
  } = ROUTE_PATHS
  if (isLoading) {
    return (
      <Loader center className="h-screen flex items-center justify-center" />
    )
  }
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ErrorHandler>
          <BrowserRouter>
            <Routes>
              <Route path="" element={<Layout />}>
                <Route path="" element={<LayoutApp />}>
                  <Route path="/" element={<Navigate to={IASTART} replace />} />
                  <Route path={IASTART} element={<IaStart />} />
                  <Route
                    path={IASTART_CONVERSATION}
                    element={<Conversation />}
                  />
                  <Route path={IASTART_APP} element={<IaStartApps />} />
                  <Route path={IADOCS} element={<IaDocs />} />
                  <Route path={IADOCS_EXTRACT} element={<ExtractPage />} />
                  <Route path={IADOCS_TYPING} element={<TypingPage />} />
                  <Route path={IADOCS_UNBUNDLE} element={<UnbundlePage />} />
                </Route>
              </Route>
              <Route path="" element={<Layout itemsCenter />}>
                <Route path={ERROR} element={<Error500 />} />
                <Route path="*" element={<Error404 />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </ErrorHandler>
      </PersistGate>
    </Provider>
  )
}

export default App
