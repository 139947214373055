import { IAMessage } from 'iastart/components/IAMessage'
import { UserMessage } from 'iastart/components/UserMessage'
import { MessageInput } from 'iastart/components/MessageInput'
import { useHandleConversation } from 'iastart/hooks/useHandleConversation'
import {
  getMessages,
  IMessage,
  UserType,
} from 'iastart/store/slices/conversationSlice'
import { useSelector } from 'react-redux'
import { RootState } from 'core/store'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as ReloadIcon } from 'iastart/assets/images/reload.svg'

export const ConversationContent: React.FC<{
  accroche: string
  applicationName: string
  placeholder: string
}> = ({ accroche, applicationName, placeholder }) => {
  const { t } = useTranslation()
  const [message, setMessage] = useState('')
  const { handleConverse, isLoading, handleResetConversation } =
    useHandleConversation(applicationName)
  const messages: IMessage[] = useSelector((state: RootState) =>
    getMessages(state, applicationName)
  )
  const messagesContainerRef = useRef<HTMLDivElement | null>(null)

  const scrollToBottom = () => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight
    }
  }

  useEffect(() => {
    scrollToBottom()
  }, [messages, isLoading])

  return (
    <>
      <div className="conversation">
        <div
          ref={messagesContainerRef}
          className="w-full h-[calc(100vh-340px)] overflow-auto"
        >
          <div className="messages-wrapper">
            <IAMessage
              message={{
                content: accroche,
                type: UserType.ASSISTANT,
                timestamp: Date.now(),
              }}
              application={applicationName}
              first
            />
            {messages.map((message, index) => {
              return message.type === UserType.ASSISTANT ? (
                <IAMessage
                  key={index}
                  message={message}
                  application={applicationName}
                />
              ) : (
                <UserMessage key={index} message={message.content} />
              )
            })}
            {isLoading && (
              <>
                <UserMessage message={message} />
                <IAMessage
                  message={{
                    content: '',
                    type: UserType.ASSISTANT,
                    timestamp: Date.now(),
                  }}
                  application={applicationName}
                  isLoading
                />
              </>
            )}
          </div>
        </div>
        <div className="message-input-wrapper">
          <div
            className="reload-conversation"
            onClick={() => handleResetConversation(applicationName)}
          >
            <ReloadIcon className="mr-[10px]" />
            {t('iastart.newConversation')}
          </div>
          <MessageInput
            placeholderText={placeholder}
            onSend={handleConverse}
            setMessage={setMessage}
          />
        </div>
      </div>
    </>
  )
}
