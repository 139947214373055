import { useCallback, useContext, useEffect, useState } from 'react'
import { CurrentHeaderContext } from 'core/contexts/CurrentHeaderContext'
import { useDropzone } from 'react-dropzone'
import { UploadedDocument } from 'iadocs/components/UploadedDocument'
import { DragZone } from 'iadocs/components/DragZone'
import { Trans } from 'react-i18next'

export const IaDocs = () => {
  const { setHeader } = useContext(CurrentHeaderContext)
  useEffect(() => {
    setHeader({
      svgPath: 'logoIaDocs.svg',
      svgPathDark: 'logoIaDocsDark.svg',
      accroche: 'iadocs.accroche',
      column: true,
    })
  }, [])
  const [error, setError] = useState(false)
  const [file, setFile] = useState<File | null>(null)

  const onDrop = useCallback((acceptedFiles: File[]) => {
    acceptedFiles.forEach((file: File) => {
      if (file.size <= 20000000) {
        if (/\.(jpg|jpeg|png|pdf)$/i.test(file.name)) {
          setFile(file)
          setError(false)
        } else {
          setError(true)
        }
      } else {
        setError(true)
      }
    })
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxSize: 20000000,
  })

  return (
    <div className="h-full w-full pt-[10px] flex flex-col items-center">
      {file ? (
        <UploadedDocument file={file} deleteFile={() => setFile(null)} />
      ) : (
        <>
          <div {...getRootProps()} className="upload-document">
            <input {...getInputProps()} />
            {isDragActive ? (
              <DragZone className="active" />
            ) : (
              <DragZone className={`${error ? 'error' : 'inactive'}`} />
            )}
          </div>
          {error && (
            <div className="dragzone-error">
              <Trans i18nKey="iadocs.upload.error" />
            </div>
          )}
        </>
      )}
    </div>
  )
}
