import { baseApi } from 'core/api/base'
import { IApplication } from 'iastart/interfaces/Application'
import { API_BASE_URL_IASTART } from 'core/constants/general'

const APPLICATIONS_ENDPOINT = API_BASE_URL_IASTART + '/applications'
const applicationApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getApplicationByName: builder.query<
      IApplication[],
      { applicationName: string }
    >({
      query: ({ applicationName }) => {
        const params = new URLSearchParams()
        params.append('nomTechnique', applicationName)
        const queryString = params.toString()
        return `${APPLICATIONS_ENDPOINT}?${queryString}`
      },
    }),
  }),
})

export const { useGetApplicationByNameQuery } = applicationApi
export { applicationApi }
