import { useConverseMutation } from 'iastart/api/conversation'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'core/store'
import {
  addMessage,
  getGuid,
  IMessage,
  resetConversation,
  UserType,
} from 'iastart/store/slices/conversationSlice'
import { IConverseBody } from 'iastart/interfaces/Conversation'
import { useNavigate } from 'react-router'
import { ROUTE_PATHS } from 'core/constants/routePath'

export const useHandleConversation = (application: string) => {
  const [converse, { isLoading }] = useConverseMutation()
  const dispatch = useDispatch()
  const guid = useSelector((state: RootState) => getGuid(state, application))
  const navigate = useNavigate()

  const handleConverse = (message: string) => {
    const body: IConverseBody = {
      application,
      params: {
        consigne: JSON.stringify(message),
      },
    }
    if (guid) {
      body.guid = guid
    } else {
      body.guid = null
    }
    converse(body)
      .unwrap()
      .then((response) => {
        const userMessage: IMessage = {
          type: UserType.USER,
          timestamp: Date.now(),
          content: message,
        }
        dispatch(
          addMessage({
            application,
            message: userMessage,
            guid: response.guid,
          })
        )
        const iaMessage: IMessage = {
          type: UserType.ASSISTANT,
          timestamp: Date.now(),
          content: response.answer,
          id: response.message_id,
        }
        dispatch(
          addMessage({
            application,
            message: iaMessage,
            guid: response.guid,
          })
        )
      })
      .catch((error) => {
        console.error(error)
        navigate(ROUTE_PATHS.ERROR)
      })
  }
  const handleResetConversation = (applicationName: string) => {
    dispatch(resetConversation({ application: applicationName }))
  }

  return {
    handleConverse,
    isLoading,
    handleResetConversation,
  }
}
