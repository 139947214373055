import { createContext, ReactNode, useState } from 'react'
import { IHeader } from 'core/interface/header'

interface ICurrentHeaderContextType {
  header: IHeader
  setHeader: (value: IHeader) => void
}

export const CurrentHeaderContext = createContext<ICurrentHeaderContextType>({
  header: {
    svgPath: '',
    svgPathDark: '',
    accroche: '',
    column: true,
  },
  setHeader: () => {},
})

export const CurrentHeaderProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const [header, setHeader] = useState<IHeader>({
    svgPath: 'logoIaStart.svg',
    svgPathDark: 'logoIaStartDark.svg',
    accroche: 'iastart.accroche',
    column: true,
  })

  return (
    <CurrentHeaderContext.Provider
      value={{
        header,
        setHeader,
      }}
    >
      {children}
    </CurrentHeaderContext.Provider>
  )
}
