import { baseApi } from 'core/api/base'
import { API_BASE_URL_IASTART } from 'core/constants/general'
import { IPrompt } from 'iastart/interfaces/Prompt'

const PROMPT_ENDPOINT = API_BASE_URL_IASTART + '/prompts/'
const promptApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getPrompt: builder.query<IPrompt, { promptId: number }>({
      query: ({ promptId }) => {
        return PROMPT_ENDPOINT + promptId.toString()
      },
    }),
  }),
})

export const { useGetPromptQuery } = promptApi
export { promptApi }
