import React from 'react'
import { ReactComponent as IconExtract } from 'iadocs/assets/images/iconExtract.svg'
import { ReactComponent as IconExtractDark } from 'iadocs/assets/images/iconExtractDark.svg'
import { ReactComponent as IconTyping } from 'iadocs/assets/images/iconTyping.svg'
import { ReactComponent as IconTypingDark } from 'iadocs/assets/images/iconTypingDark.svg'
import { ReactComponent as IconUnbundle } from 'iadocs/assets/images/iconUnbundle.svg'
import { ReactComponent as IconUnbundleDark } from 'iadocs/assets/images/iconUnbundleDark.svg'
import { IconType } from 'iadocs/type/IconType'

interface IconSelectorProps {
  type: IconType
  isDarkTheme: boolean
}

export const IconSelector: React.FC<IconSelectorProps> = ({
  type,
  isDarkTheme,
}) => {
  const icons: Record<IconType, { light: JSX.Element; dark: JSX.Element }> = {
    extract: {
      light: <IconExtract />,
      dark: <IconExtractDark />,
    },
    typing: {
      light: <IconTyping />,
      dark: <IconTypingDark />,
    },
    unbundle: {
      light: <IconUnbundle />,
      dark: <IconUnbundleDark />,
    },
  }

  const IconComponent = icons[type]?.[isDarkTheme ? 'dark' : 'light'] || null

  return (
    <div>
      {IconComponent ? (
        IconComponent
      ) : (
        <p>Unknown type: {type}</p> // Message pour un type non supporté
      )}
    </div>
  )
}
