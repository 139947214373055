import { IApplication } from 'iastart/interfaces/Application'
import { useTestPrompt } from 'iastart/hooks/useTestPrompt'
import Loader from 'core/components/common/Loader'
import React, { useRef, useState } from 'react'
import { IAMessage } from 'iastart/components/IAMessage'
import {
  getMessages,
  IMessage,
  UserType,
} from 'iastart/store/slices/conversationSlice'
import { useTranslation } from 'react-i18next'
import { VariablesInput } from 'iastart/components/VariablesInput'
import { useSelector } from 'react-redux'
import { RootState } from 'core/store'

export const PromptTest: React.FC<{ application: IApplication }> = ({
  application,
}) => {
  const { prompt, isLoadingPrompt } = useTestPrompt(application)
  const { t } = useTranslation()
  const messagesContainerRef = useRef<HTMLDivElement | null>(null)
  const messages: IMessage[] = useSelector((state: RootState) =>
    getMessages(state, application.nomTechnique)
  )
  const [isSending, setIsSending] = useState<boolean>(false)
  if (isLoadingPrompt) {
    return (
      <Loader
        center
        className="h-[calc(100vh-180px)] flex items-center justify-center"
      />
    )
  }
  if (!prompt) {
    return null
  }
  return (
    <>
      <div className="conversation">
        <div
          ref={messagesContainerRef}
          className="w-full h-[calc(100vh-300px)] overflow-auto"
        >
          <div className="messages-wrapper">
            <IAMessage
              message={{
                content: t('iastart.application.accroche', {
                  application: application.nomApplication,
                }),
                type: UserType.ASSISTANT,
                timestamp: Date.now(),
              }}
              application={application.nomApplication}
              first
            />
            {isSending && (
              <IAMessage
                message={{
                  content: '',
                  type: UserType.ASSISTANT,
                  timestamp: Date.now(),
                }}
                application={application.nomApplication}
                isLoading
              />
            )}
            {!isSending &&
              messages.map((message, index) => {
                return (
                  <IAMessage
                    key={index}
                    message={message}
                    application={application.nomTechnique}
                  />
                )
              })}
          </div>
        </div>
        <div className="message-input-wrapper">
          <VariablesInput
            prompt={prompt}
            application={application}
            setIsSending={setIsSending}
          />
        </div>
      </div>
    </>
  )
}
